<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
//import axios from "axios";

/**
 * Profile component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Migration",
    meta: [
      {
        name: "migration",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Migration",
      user2: null,
      ingredients: null,
      recettes: null,
      exercices: null,
      workoutsbasic: null,
      lastVisible: null,
      videoslive: null,
      programsbasic: null,
      programspremium: null,
      historicworkouts: null,
      evolutionusers: null,
      discussions: null,
      users: null,
      datausers: null,
      finishSync: "",
      jsonpost: "",
      items: [
        {
          text: "Tickets",
        },
        {
          text: "Tickets",
          active: true,
        },
      ],
    };
  },
  methods: {
    async importPost() {
      var myJson2 = JSON.parse(this.jsonpost);
      var db = firebase.firestore();
      for (var element in myJson2) {
        if (myJson2[element].created_by_ok != 0) {
          var attachments = [];
          var content = JSON.parse(myJson2[element].content);
          if (content.it != null && content.it.attachments != null) {
            for (var attach in content.it.attachments) {
              if (content.it.attachments[attach].imageUrl != null) {
                var type = content.it.attachments[attach].imageUrl
                  .split(".")
                  .pop();
                var newurl = await this.transferMediaGoogle(
                  content.it.attachments[attach].imageUrl,
                  myJson2[element].id,
                  type
                );
                attachments.push(newurl);
              }
            }
          }

          var referenceuser = db
            .collection("users")
            .doc(myJson2[element].created_by_ok.toString());

          var newpost = {
            created_by: referenceuser,
            parent_id: myJson2[element].parent_id
              ? myJson2[element].parent_id
              : null,
            created_at: new Date(myJson2[element].created_at).getTime(),
            lang: "it",
            content:
              content.it != null && content.it.text != null
                ? content.it.text
                : "",
            attachments: attachments,
            /*giphyUrl:
              myJson2[element].properties != null &&
              JSON.parse(myJson2[element].properties)["giphyUrl"]
                ? JSON.parse(myJson2[element].properties)["giphyUrl"]
                : null,*/
            status: myJson2[element].status,
          };
          console.log(newpost.toString())
          await db
            .collection("community")
            .doc(myJson2[element].id)
            .set(newpost);
        }
      }
    },
    async transferMediaGoogle(remoteMedia, idmedia, type) {
      var filename =
        "photo_community/" + idmedia + "-" + Date.now() + "." + type;
      try {
        const res = await fetch(remoteMedia);
        const blob = await res.blob();
        //uploading blob to firebase storage
        return firebase
          .app()
          .storage("gs://gabriellavicofitness")
          .ref()
          .child(filename)
          .put(blob)
          .then(function (snapshot) {
            console.log(snapshot.ref.getDownloadURL());
            return snapshot.ref.getDownloadURL();
          })
          .then((url_1) => {
            console.log("Firebase storage image uploaded : ", url_1);
            return url_1;
          });
      } catch (error) {
        console.error("erreur avec " + remoteMedia + " : " + error);
      }
    },
  },

  create() {},
  mounted() {
    this.user2 = localStorage.getItem("user2");
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <textarea
          v-model="jsonpost"
          placeholder="add multiple lines"
        ></textarea>
        <hr />
        <button type="button" class="btn btn-primary" @dblclick="importPost()">
          Sync Role with Aweber
        </button>
        {{ finishSync }}
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
